import { ExclamationIcon } from "components/icons";

export const ErrorMessage = ({ children, className = "" }) => {
    return children ? (
        <div>
            <div className="h-2 text-green-500">
                <svg className="ml-6 h-full" viewBox="0 0 10 5">
                    <polygon points="0,5 5,0 10,5" fill="currentColor" />
                </svg>
            </div>
            <div className={`flex flex-row items-start gap-2 p-2 bg-green-500 rounded-md ${className||""}`}>
                <div className="mt-3/4 pt-1/2">
                    <div className="bg-blue-500 w-5 h-5 p-1 rounded-full flex justify-center items-center">
                        <ExclamationIcon className="w-[10px] h-[10px] text-white" />
                    </div>
                </div>
                <span>{children}</span>
            </div>
        </div>
    ) : null;
}
