import DatePicker from 'antd/lib/date-picker';
import { ErrorMessage } from './field'

export const DatePickerAdapter = ({ input, meta, label, icon, dark = false, className = "", ...rest }) => (
    <div className={`flex flex-col min-w-[140px] ${className}`}>
        <label className={`block mb-4 h-7 ${dark ? 'text-white' : 'text-pink-500'}`}>
            <div className="flex flex-row items-center gap-2">
                {icon}
                <span>{label}</span>
            </div>
        </label>
        <DatePicker 
            {...input}
            {...rest}
            className={`border h-10 pl-0 ${dark ? 'border-gray-500':'border-pink-500'} ${(meta.touched && meta.error) ? 'border-red-500': ''} cursor-pointer`}
            inputReadOnly // hides keyboard on mobile
        />
        <ErrorMessage>{meta.touched && meta.error}</ErrorMessage>
    </div>
)
